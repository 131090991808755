import React from 'react'
import './News.css'
// import { Link } from 'react-router-dom';
import Ogloszenia from './Ogloszenia'

function News() {
  return (
    <>
      <div className='news-container'>
      <Ogloszenia />
        {/* <div className='items'>
          <div className='aktualnosci'>
            <h1>Ogłoszenia</h1>
            <br></br>
            <h3>Natychmiast aktualizujemy wszystkie wydarzenia dotyczące przedszkola</h3>
            <br></br>
          </div>
          <hr></hr>
          <div className='strefa'>
            <h1>Strefa rodzica</h1>
            <br></br>
            <h3>Wpisy zabezpieczone hasłem <i className="fa-solid fa-lock"/></h3>
            <br></br>
            <Link to="/strefa">
            <button><h3>Przejdź</h3></button></Link>
        </div>  
        </div> */}
        <div className='extra'>
          <table cellSpacing="10">
            <tr className='header-table-row'><th colSpan="6" className='header-table'><h1>ZAJĘCIA DODATKOWE</h1><h2> w roku szkolnym 2022/2023</h2></th></tr>
            <div className='table-row'>
              <tr>
                <td align="center"><h3>Poniedziałek</h3><hr></hr><h4>język angielski</h4></td>
                <td align="center"><h3>Wtorek</h3><hr></hr><h4></h4></td>
                <td align="center"><h3>Środa</h3><hr></hr><h4>zajęcia sensoryczne z elementami terapii ręki</h4></td>
                <td align="center"><h3>Czwartek</h3><hr></hr><h4>"Przedszkoliada" - zajęcia sportowe</h4></td>
                <td align="center"><h3>Piątek</h3><hr></hr><h4>"Jazzy" - zajęcia taneczne</h4></td>
              </tr>
            </div>
            <div className='table-column'>
              <tr><td align="center"><div className='column-item'><h3>Poniedziałek</h3><hr></hr><h4>język angielski</h4></div></td></tr>
              <tr><td align="center"><div className='column-item'><h3>Wtorek</h3><hr></hr><h4></h4></div></td></tr>
              <tr><td align="center"><div className='column-item'><h3>Środa</h3><hr></hr><h4><span>zajęcia sensoryczne</span><br/><span>z elementami terapii ręki</span></h4></div></td></tr>
              <tr><td align="center"><div className='column-item'><h3>Czwartek</h3><hr></hr><h4><span>"Przedszkoliada"</span><br/><span>zajęcia sportowe</span></h4></div></td></tr>
              <tr><td align="center"><div className='column-item'><h3>Piątek</h3><hr></hr><h4><span>"Jazzy"</span><br/><span>zajęcia taneczne</span></h4></div></td></tr>
            </div>
          </table>
        </div>
        <div className='plan'>
          <h1>RAMOWY PLAN DNIA</h1>
          <div className='dots'>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <div className='lines'>
            <h3>7<sup>00</sup> - 8<sup>15</sup> schodzenie się dzieci - powitanie, rozmowy, zabawy indywidualne, zachęcanie do zabawy z innymi, krótkie zabawy ze śpiewem, naśladowcze, itp.</h3>
            <hr></hr>
            <h3>8<sup>15</sup> - 8<sup>30</sup> zabawy ruchowe, czynności higieniczne przed posiłkiem</h3>
            <hr></hr>
            <h3>8<sup>30</sup> - 9<sup>00</sup> śniadanie</h3>
            <hr></hr>
            <h3>9<sup>00</sup> - 9<sup>30</sup> zajęcia edukacyjne rozwijające różnorodną aktywność dziecięcą, muzyczną, ruchową, poznawczą, matematyczną, rytmiczną, itp.</h3>
            <hr></hr>
            <h3>9<sup>30</sup> - 10<sup>40</sup> kontynuowanie zabaw dzieci, zajęcia dodatkowe (30 minut)</h3>
            <hr></hr>
            <h3>10<sup>40</sup> - 11<sup>15</sup> spacery, zabawy na placu zabaw lub na pobliskim boisku</h3>
            <hr></hr>
            <h3>11<sup>15</sup> - 11<sup>35</sup> zabawy swobodne</h3>
            <hr></hr>
            <h3>11<sup>35</sup> - 11<sup>55</sup> czynności higieniczne, obiad</h3>
            <hr></hr>
            <h3>12<sup>00</sup> - 13<sup>40</sup> leżakowanie (dzieci młodsze), relaksacja starszaków</h3>
            <hr></hr>
            <h3>13<sup>00</sup> - 13<sup>40</sup> zajęcia edukacyjne starszaków</h3>
            <hr></hr>
            <h3>14<sup>00</sup> - 14<sup>20</sup> podwieczorek</h3>
            <hr></hr>
            <h3>14<sup>20</sup> - 16<sup>30</sup> zabawy ruchowe, zabawy swobodne, praca indywidualna z dzieckiem, rozchodzenie się dzieci</h3>
            <hr></hr>
          </div>      
        </div>
      </div>
    </>
  )
}

export default News